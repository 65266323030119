import client from "./client"

export const dinersService = {
    markFinished,
    currentData,
    updateData,
    verifySMSCode,
    uploadDocument,
    removeDocument,
    eligible,
    validateVAT,
    verifySMSCodeStatus,
    verifyExistingCard
}

function markFinished() {
    return updateData({
        'step_id': 'finished'
    })
}

function currentData(){
    return client.post('/api/diners/data/current', {});
}

function updateData(data){
    return client.post('/api/diners/data/update', data);
}

function verifySMSCode(code){
    return client.post('/api/diners/verify-sms', {
        'sms_code': code
    });
}

function uploadDocument(document_type, file) {
    const data = {
      'document_type': document_type,
    };
    return client.uploadFile('/api/diners/upload-document', file, data);
}

function removeDocument(document_type) {
    return client.post('/api/diners/remove-document', {
        'document_type': document_type
    })
}

function eligible() {
    return client.post('/api/diners/eligible')
}


function validateVAT(vat_number) {
    const data = {
        vat_number: vat_number
    };
    return client.post('/api/diners/validate-vat', data);
}

function verifySMSCodeStatus(){
    return client.post('/api/diners/verify-sms-status', {

    });
}


function verifyExistingCard(card_number, condition){
    return updateData({
        step_id: 'existing-card-verify',
        card_number: card_number,
        condition: condition
    })
}