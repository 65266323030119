import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store/index'
import utils from './utils'
import tracking from "./plugins/tracking";


Vue.config.productionTip = false;
import './styles/style.less';

Vue.filter('localDate', function (value) {
  return utils.formatSlovenianDate(value);
});

Vue.use(tracking, {
  store: store
});




new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
