import client from "./client"

export const shoppingListService = {
    linkStatus,
    linkConnect
};

function linkStatus(linkId){
    return client.post('/api/shopping-list/status', {
        'link_id': linkId
    });
}

function linkConnect(linkId) {
    return client.post('/api/shopping-list/connect', {
        'link_id': linkId
    });
}

