import client from './client'

export const eInvoiceService = {
  confirm
}

function confirm (confirmationCode) {
  return client.post('/api/einvoice/confirm', {
    'code': confirmationCode
  })
}
