var tracking = {};

tracking.config = {};

tracking.install = function (Vue, options) {
    var store = options['store'];
    if (store == undefined){
        // eslint-disable-next-line
        console.error("Tracking: Store if required!");
    }

    function trackEvent (eventName, data){
        let application = null;
        if (store && store.state && store.state.application){
            application = store.state.application.title;
        }
        var push = JSON.parse(JSON.stringify(data));
        push['event'] = eventName;
        push['application'] = application;
        if (window.dataLayer) {
            window.dataLayer.push(push);
        }
    }

    Vue.track = trackEvent;
    Vue.prototype.$trackUserEvent = trackEvent;
    Vue.prototype.$trackPageVisit = function(page, otherData){
        var push = {};
        if (otherData != undefined){
            push = JSON.parse(JSON.stringify(otherData));
        }
        push['page'] = page;
        trackEvent('page_visit', push);
    };
};

export default tracking;
