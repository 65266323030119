import client from "./client"

export const appsService = {
    getAppSettings,
    saveAppSettings,
    unsubscribe,
    updateCommunicationChannels
};

function getAppSettings(application){
    return client.post('/api/apps/get-user-settings', {
        'application': application
    });
}


function saveAppSettings(application, settings){
    return client.post('/api/apps/save-user-settings', {
        'application': application,
        'settings': settings
    });
}

function unsubscribe(application){
    return client.post('/api/apps/unsubscribe', {
        'application': application
    });
}

function updateCommunicationChannels(application, channels){
    return client.post('/api/apps/update-communication-channels', {
        'application': application,
        'channels': channels
    });
}




