// initial state
import Router from '../../router'
import { applicationService } from '../../services'

const state = {
  title: 'Tuš',
  logo: undefined,
  allow_join_club: true,
  allow_upgrade_to_club: true,
  flow_data: null,
  application_type: null,
  loaded: false,
  urls: {
    login: {
      fb: undefined,
      google: undefined
    }
  },
  redirectInProgress: {
    status: false,
    text: '',
  },
  sent_email: {
    'title': 'Le še potrditev e-naslova',
    'text': 'Za zaključek postopka prosim potrdite povezavo, ki smo jo poslali na vaš e-naslov.'
  },
  activeSection: 'account',
  customLogo: null,
}

// getters
const getters = {}

// actions
const actions = {
  fetchInitialInfo ({ dispatch, commit }) {
    applicationService.info().then(
      data => {
        commit('setApplicationLoaded', true)
        if (data.flow) {
          commit('setApplicationTitle', data.application.name)
          commit('setApplicationLogo', data.application.logo)
          commit('setAllowJoinClub', data.application.allow_join_club)
          commit('setApplicationType', data.application.app_type)
          commit('setFlowData', data.flow_data)
        }
        commit('setUrls', data.urls)
        // handle custom scripts load for apps
        if (data.application && data.application.app_type == 'HN') {
          //custom script fot HN
          let chatScript = document.createElement('script')
          chatScript.setAttribute('src', '//code.tidio.co/bdmrbeiioe346ya48cngeyjejqftfyfu.js')
          chatScript.async = true
          document.head.appendChild(chatScript)
        }
        // end handle
        dispatch('auth/me', {}, { root: true })
      },
      () => {
        commit('setApplicationLoaded', true)
        commit('setApplicationTitle', 'Tuš')
        commit('setApplicationLogo', null)
        commit('setAllowJoinClub', true)
        commit('setApplicationType', null)
        dispatch('auth/me', {}, { root: true })
      }
    )
  },
  emailSentMessage ({ commit }, { title, text }) {
    commit('setEmailSent', {
      'title': title,
      'text': text
    })
    Router.push('/poslan-email')
  },
  redirect ({ commit }, { url, internal, message }) {
    if (internal) {
      Router.push(url)
      return
    }
    commit('setRedirectInProgress', {
      status: true,
      text: message
    })
    window.location.replace(url)
  }
}

// mutations
const mutations = {
  setApplicationLoaded (state, loaded) {
    state.loaded = loaded
  },
  setApplicationTitle (state, title) {
    state.title = title
  },
  setApplicationLogo (state, logo) {
    state.logo = logo
  },
  setAllowJoinClub (state, status) {
    state.allow_join_club = status
  },
  setApplicationType (state, type) {
    state.application_type = type
  },
  setFlowData (state, data) {
    state.flow_data = data
  },
  setUrls (state, urls) {
    state.urls = urls
  },
  setRedirectInProgress (state, { status, text }) {
    state.redirectInProgress.status = status
    state.redirectInProgress.text = text
  },
  setEmailSent (state, { title, text }) {
    state.sent_email = {
      title: title,
      text: text
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
