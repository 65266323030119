var cos = require("i18n-iso-countries");
cos.registerLocale(require("i18n-iso-countries/langs/sl.json"));

const countries = [
    {
        text: "Slovenija",
        value: "SI"
    },
    {
        text: "Hrvaška",
        value: "HR"
    },
    {
        text: "Madžarska",
        value: "HU"
    },
    {
        text: "Avstrija",
        value: "AT"
    },
    {
        text: "Italija",
        value: "IT"
    },
    {
        text: "Drugo",
        value: "XX"
    },
];

const genders = [
    {
        'text': 'ženski',
        'value': 1
    },
    {
        'text': 'moški',
        'value': 2
    }
];

const educations = [
    {
        text: 'Brez',
        value: 1
    },
    {
        text: 'Osnovna',
        value: 2
    },
    {
        text: 'Poklicna',
        value: 3
    },
    {
        text: 'Srednješolska',
        value: 4
    },
    {
        text: 'Višja',
        value: 5
    },
    {
        text: 'Visoka',
        value: 6
    },
    {
        text: 'Magisterij',
        value: 7
    },
    {
        text: 'Doktorat',
        value: 8
    }
];

const educationsDiners = [
    {
        text: 'I. stopnja izobrazbe',
        value: 'level_1'
    },
    {
        text: 'II. stopnja izobrazbe',
        value: 'level_2'
    },
    {
        text: 'III. stopnja izobrazbe',
        value: 'level_3'
    },
    {
        text: 'IV. stopnja izobrazbe',
        value: 'level_4'
    },
    {
        text: 'V. stopnja izobrazbe',
        value: 'level_5'
    },
    {
        text: 'VI. stopnja izobrazbe',
        value: 'level_6'
    },
    {
        text: 'VII. stopnja izobrazbe',
        value: 'level_7'
    },
    {
        text: 'VIII. stopnja izobrazbe',
        value: 'level_8'
    },

];

const employment = [
    {
        'text': 'Zaposlen/-a v podjetju (nedoločen čas)',
        'value': 'employed_indefinitely'
    },
    {
        'text': 'Zaposlen/-a v podjetju (določen čas)',
        'value': 'employed_fixed_term'
    },
    {
        'text': 'Lastnik/-ca podjetja',
        'value': 'owner_company'
    },
    {
        'text': 'Samostojni podjetnik/-ca',
        'value': 'entrepreneur'
    },
    {
        'text': 'Študent/-ka',
        'value': 'student'
    },
    {
        'text': 'Upokojenec/-ka',
        'value': 'retired'
    },
    {
        'text': 'Drugo',
        'value': 'other'
    }
];


const personStatus = [
    {
        text: 'Ni podatka',
        value: 0
    },
    {
        text: 'Zaposlen',
        value: 1
    },
    {
        text: 'Upokojen',
        value: 2
    },
    {
        text: 'Drugo',
        value: 3
    }
];

const familyMembers = [
    /*{
        text: 'Ni podatka',
        value: 0
    },
    {
        text: 'Sam',
        value: 1
    },*/
    {
        text: '2',
        value: 2
    },
    {
        text: '3',
        value: 3
    },
    {
        text: '4',
        value: 4
    },
    {
        text: '5',
        value: 5
    },
    {
        text: '6 ali več',
        value: 6
    }
];

const familyMembersWithNone = [
    {
        text: 'Ni podatka',
        value: 0
    },
    {
        text: 'Sam',
        value: 1
    },
    {
        text: '2',
        value: 2
    },
    {
        text: '3',
        value: 3
    },
    {
        text: '4',
        value: 4
    },
    {
        text: '5',
        value: 5
    },
    {
        text: '6 ali več',
        value: 6
    }
];

const residence = [
    {
        'text': 'Lastna hiša',
        'value': 'own_house'
    },
    {
        'text': 'Lastno stanovanje',
        'value': 'own_appartment'
    },
    {
        'text': 'Najemnik',
        'value': 'tenant'
    },
    {
        'text': 'Pri partnerju',
        'value': 'at_partner'
    },
    {
        'text': 'Pri starših',
        'value': 'at_parents'
    },
    {
        'text': 'Drugo',
        'value': 'other'
    }
];

const maritalStatus = [
    {
        'text': 'Poročen/-a',
        'value': 'married'
    },
    {
        'text': 'Samski/-a',
        'value': 'single'
    },
    {
        'text': 'Zunajzakonska skupnost',
        'value': 'extramarital_community'
    },
    {
        'text': 'Ločen/-a',
        'value': 'divorced'
    },
    {
        'text': 'Vdovec/-a',
        'value': 'widower'
    },
    {
        'text': 'Drugo',
        'value': 'other'
    }
];

const citizenship = [];
let ciCou = cos.getNames("sl",{select: "official"});
for(let code of Object.keys(ciCou)) {
    let nCode = cos.alpha2ToNumeric(code);
    citizenship.push({
        'value': nCode,
        'text': ciCou[code]
    })
}

function maskCardNumber(number) {
    let masked = '';
    let l = number.length;
    masked = number.substring(l - 4);
    while (masked.length < 13) {
        masked = '*' + masked;
    }
    return masked
}

function formatSlovenianDate(value){
    return formatDate(value, 'sl-si');
}

function toDateFromSlovenianDate(value){
    const [day, month, year] = value.split('.');
    return new Date(year+'-'+month+'-'+day);
}

function formatDate(value, locale){
    let date = new Date(value);
    return date.toLocaleDateString(locale)
}

function formatAPIDate(value){
    var day = value.getDate();
    var monthIndex = value.getMonth();
    var year = value.getFullYear();
    return year+'-'+(monthIndex+1)+'-'+day+' 00:00:00.000';
}

function formatAPIDateOnly(value){
    var day = value.getDate();
    var monthIndex = value.getMonth();
    var year = value.getFullYear();
    return year+'-'+(monthIndex+1)+'-'+day;
}

function countryName(value){
    for(let country of countries){
        if (country.value == value){
            return country['text'];
        }
    }
    return '/';
}

function genderName(value){
    for(let gender of genders){
        if (gender.value == value){
            return gender['text'];
        }
    }
    return '/';
}

function educationName(value){
    for(let education of educations){
        if (education.value == value){
            return education['text'];
        }
    }
    return '/';
}

function personStatusName(value){
    for(let personS of personStatus){
        if (personS.value == value){
            return personS['text'];
        }
    }
    return '/';
}


function familyMembersName(value){
    for(let fm of familyMembers){
        if (fm.value == value){
            return fm['text'];
        }
    }
    return '/';
}


export default {
    maskCardNumber,
    formatDate,
    formatAPIDate,
    formatAPIDateOnly,
    formatSlovenianDate,
    countries,
    genders,
    educations,
    educationsDiners,
    personStatus,
    familyMembers,
    familyMembersWithNone,
    employment,
    residence,
    maritalStatus,
    citizenship,
    countryName,
    genderName,
    educationName,
    personStatusName,
    familyMembersName,
    toDateFromSlovenianDate
}
