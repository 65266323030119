<template>
    <v-app>
        <div v-if="user_loaded">
            <div class="redirect-overlay" v-if="redirectInProgress">
                <p>{{ redirectInProgressText }}</p>
                <v-progress-circular :indeterminate="true" size="50"></v-progress-circular>
            </div>
            <v-main>
                <SSOHeader/>
                <v-container>
                    <v-layout align-center justify-center row>
                        <router-view/>
                    </v-layout>
                    <v-layout align-center justify-center row v-if="authenticated" class="footer-user-info">
                        <div>
                            Prijavljeni ste kot <b>{{ authenticated_user.email }}</b>
                            <v-btn @click="logOut" fab text x-small>
                                <v-icon>fas fa-sign-out-alt</v-icon>
                            </v-btn>
                        </div>
                    </v-layout>
                </v-container>
            </v-main>
            <v-snackbar
                    v-model="api_connection_problem"
                    :timeout="0"
                    color="error"
            >Prišlo je do težave pri obdelavi zahteve na strežniku. Prosimo poskusite kasneje.
            </v-snackbar>
        </div>
        <div class="application-loader">
            <v-progress-circular :indeterminate="true" size="50" v-if="!user_loaded"></v-progress-circular>
        </div>
    </v-app>
</template>

<script>
    import SSOHeader from "./components/SSOHeader";
    import utils from "./store/utils";


    export default {
        name: 'App',
        components: {
            SSOHeader
        },
        data() {
            return {}
        },
        computed: {
            api_connection_problem: utils.vuexBuildSetGet(this, null, 'api_connection_problem'),
            user_loaded: utils.vuexBuildSetGet(this, null, 'auth.user_loaded'),
            authenticated: utils.vuexBuildSetGet(this, null, 'auth.authenticated'),
            authenticated_user: utils.vuexBuildSetGet(this, null, 'auth.authenticated_user'),
            authenticated_checked: utils.vuexBuildSetGet(this, null, 'auth.authenticated_checked'),
            redirectInProgress: utils.vuexBuildSetGet(this, null, 'application.redirectInProgress.status'),
            redirectInProgressText: utils.vuexBuildSetGet(this, null, 'application.redirectInProgress.text'),
            application_type: utils.vuexBuildSetGet(this, 'application', 'application_type'),
        },
        created() {
            this.$store.dispatch('application/fetchInitialInfo');
        },
        methods: {
            logOut(){
                this.$store.dispatch('auth/logout');
            }
        }
    }
</script>

<style scoped>
    .redirect-overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #F4F4F4;
        z-index: 9999;
        text-align: center;
        font-size: 2em;
        padding-top: 30px;
    }

    .application-loader{
        text-align: center;
        padding: 25px;
    }

    .footer-user-info {
        padding: 15px 0px 15px 0px;
        font-style: italic;
    }
</style>
