<template>
    <v-layout row wrap class="header" v-if="appLoaded">
      <template v-if="!isCustomLogo">
        <template v-if="isTusTeam">
          <v-img
            :src="tusTeamLogo"
            contain
            title="Tuš TEAM"
            height="70"
            class="logo"
          ></v-img>
        </template>
        <template v-if="!isTusTeam">
          <v-img
            :src="appLogo"
            contain
            :title="appTitle"
            height="70"
            class="logo"
            v-on:click="goTo"
          ></v-img>
        </template>
      </template>
      <template v-if="isCustomLogo">
        <v-img
          :src="customLogo.url"
          contain
          :title="customLogo.title"
          :height="customLogo.height"
          class="logo"
        ></v-img>
      </template>
    </v-layout>
</template>

<script>
    import utils from "../store/utils";

    export default {
        name: "SSOHeader",
        computed: {
            appLoaded() {
                return this.$store.state.application.loaded;
            },
            appTitle() {
                if (this.$store.state.application.title) {
                    return this.$store.state.application.title;
                }
                return 'Tuš'
            },
            appLogo() {
                if (this.$store.state.application.logo) {
                    return this.$store.state.application.logo;
                }
                return require('../assets/tus.jpg');
            },
            tusTeamLogo() {
                return require('../assets/tus_team.png');
            },
            isTusTeam(){
              return this.activeSection == 'tus-team';
            },
            isCustomLogo() {
              return this.customLogo != null;
            },
            activeSection: utils.vuexBuildSetGet(this, null, 'application.activeSection'),
            customLogo: utils.vuexBuildSetGet(this, null, 'application.customLogo'),
        },
        methods: {
            goTo() {
                window.location.replace('/');
            }
        }
    }
</script>

<style scoped>
    .header {
        padding-top: 60px;
        padding-bottom: 50px;
    }

    .header .logo {
        cursor: pointer;
    }

    @media only screen and (max-width: 600px) {
        .header {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
</style>
