import { userService } from '../../services'

// initial state
const state = {
  loading: true,
  silentLoading: false,
  silentLoadingSection: undefined,
  error: false,
  error_code: undefined,
  user: {},
  sessions: {
    count: 0
  }
}

// getters
const getters = {}

// actions
const actions = {
  allAboutMe ({ commit }, params) {
    let user_key = undefined
    if (params) {
      user_key = params['user_key']
    }
    commit('setLoading', true)
    commit('setError', false)
    userService.allAboutMe(user_key).then(
      data => {
        commit('setAuthenticatedUserData', data.user)
        commit('setSessionsData', data.sessions)
      },
      error => {
        //if (error.error_code != 18) {
        commit('setError', true)
        //}
        commit('setErrorCode', error.error_code)
      }
    ).then(function () {
      commit('setLoading', false)
    })
  },
  silentAllAboutMe ({ commit }, params) {
    if (params == undefined) {
      params = {}
    }
    params = Object.assign({
      'loader': true,
      'section': undefined
    }, params)
    commit('setSilentLoading', true)
    commit('setSilentLoadingSection', params['section'])
    commit('setError', false)
    userService.allAboutMe().then(
      data => {
        commit('setAuthenticatedUserData', data.user)
        commit('setSessionsData', data.sessions)
      },
      error => {
        commit('setError', true)
        commit('setErrorCode', error.error_code)
      }
    ).then(function () {
      commit('setSilentLoading', false)
      commit('setSilentLoadingSection', null)
    })
  }
}

// mutations
const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },
  setSilentLoading (state, loading) {
    state.silentLoading = loading
  },
  setSilentLoadingSection (state, section) {
    state.silentLoadingSection = section
  },
  setError (state, error) {
    state.error = error
  },
  setErrorCode (state, error_code) {
    state.error_code = error_code
  },
  setAuthenticatedUserData (state, user) {
    if (!user) {
      user = {}
    }
    state.user = user
  },
  setSessionsData (state, sessions) {
    state.sessions = sessions
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
