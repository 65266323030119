import client from "./client"

export const registrationService = {
    postOffice,
    joinClub
};

function postOffice(zip){
    return client.post('/api/post-office/', {
        'zip': zip
    });
}

function joinClub(data){
    return client.post('/api/join-club/', data);
}