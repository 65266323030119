import { getObjectValueByPath } from 'vuetify/lib/util/helpers'

function setObjectValueByPath (object, path, value) {
  var a = path.split('.')
  var o = object
  for (var i = 0; i < a.length - 1; i++) {
    var n = a[i]
    if (n in o) {
      o = o[n]
    } else {
      o[n] = {}
      o = o[n]
    }
  }
  o[a[a.length - 1]] = value
}

function vuexBuildSetGet (component, module, path, mutation, action_after_change, default_for_null) {
  if (mutation == undefined || mutation == null) {
    mutation = 'setByPath'
  }
  let result = {
    get () {
      if (module == null) {
        module = this.vuex_module
      }
      let from_state = this.$store.state
      if (module) {
        from_state = from_state[module]
      }
      result = getObjectValueByPath(from_state, path)
      return result
    },
    set (value) {
      if (module == null) {
        module = this.vuex_module
      }
      let my_path = path
      if (module) {
        my_path = module + '.' + path
      }
      if (default_for_null && value == null) {
        value = default_for_null
      }
      this.$store.commit(mutation, {
        'p': my_path,
        'v': value
      })
      if (action_after_change) {
        this.$store.dispatch(action_after_change)
      }
    }
  }
  return result
}

export default {
  vuexBuildSetGet,
  setObjectValueByPath,
  getObjectValueByPath
}
