import client from './client'

export const loyaltyService = {
  getUserClubs,
  joinSpecialClub
}

function getUserClubs () {
  return client.get('/api/loyalty/clubs')
}

function joinSpecialClub (club_id, data) {
  return client.post('/api/loyalty/join_special_club/' + club_id, data)
}
