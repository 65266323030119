import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import profile from './modules/profile'
import registration from './modules/registration'
import application from './modules/application'
import link_card from './modules/link_card'
import utils from './utils'
/*
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})
*/

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      api_connection_problem: false,
      loading: false
    },
    modules: {
      auth,
      registration,
      application,
      link_card,
      profile
    },
    mutations: {
      setByPath (state, payload) {
        utils.setObjectValueByPath(state, payload['p'], payload['v'])
      },
      loading (state, status) {
        state.loading = status
      },
      api_connection_problem (state, status) {
        state.api_connection_problem = status
      }
    },
    //plugins: [vuexLocal.plugin]
  }
)
