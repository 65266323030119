import Store from '../store/index';
import axios from 'axios'

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

class Client{

    constructor () {
        this.loading = false;
    }

    _executeWrapper(command){
        var that = this;
        return new Promise(function(resolve, reject) {
            that.loading = true;
            var lazy_loader = setTimeout(function(){
                // show loader if data is still loading. Solution to now show loader everytime, just when it takes more time
                if (that.loading){
                    Store.commit('loading', true);
                }
            }, 200);
            Store.commit('api_connection_problem', false);
            command.then(
                (response) => {
                    //OK callback
                    that.loading = false;
                    clearTimeout(lazy_loader);
                    Store.commit('loading', false);
                    resolve(response.data.data);
                },
                (error) => {
                    that.loading = false;
                    clearTimeout(lazy_loader);
                    Store.commit('loading', false);
                    if (error.response.status != 500 || (error.response.status == 500 && error.response.data.error_code == undefined)) {
                        // eslint-disable-next-line
                        console.log('ERR response', error.response);
                        Store.commit('api_connection_problem', true);
                    }
                    //error callback
                    reject(error.response.data);
                }
            );
        });
    }


    get(url){
        var command = axios.get(url, {withCredentials: true});
        return this._executeWrapper(command);
    }

    post(url, data){
        var command = axios.post(url, data, {withCredentials: true});
        return this._executeWrapper(command);
    }

    uploadFile(url, file, data){
        let formData = new FormData();
        if (data){
            for(const key in data){
                formData.append(key, data[key]);
            }
        }
        formData.append('file', file);
        let command = axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        })
        return this._executeWrapper(command);
    }
}

export default new Client();
