import client from "./client"

export const applicationService = {
    info,
    getRequiredConsents,
    confirmRequiredConsents
};

function info(){
    return client.get('/api/application/');
}

function getRequiredConsents(){
    return client.get('/api/application/get-required-consents');
}

function confirmRequiredConsents(){
    return client.post('/api/application/confirm-required-consents');
}