// initial state
import Router from '../../router'
import { registrationService } from '../../services'

const state = {
  confirm_all_consents: false,
  personal: {
    name: null,
    last_name: null,
    street: null,
    house_number: null,
    house_number_ext: null,
    zip: null,
    city: null,
    gender: null,
    date_of_birth: null,
    country: null,
    phone_number: null,
    phone_number_land: null,
    education: 1,
    family_members: 0,
    status: 0,
    join_family_club: false,
    free_gift: false,
    free_gift_store: null,
    childs: [],
  },
  account: {
    email: null,
    password: null,
    password_again: null,
    confirm_cond: false
  },
  interests: {
    fruit_vegatable: false,
    bread_and_pastries: false,
    vegan_products: false,
    no_gluten_lactose: false,
    meat_and_delicatessen: false,
    cosmetics_cleaning: false,
    babies_toddlers: false,
    toys: false,
    non_food_offer: false,
  },
  consents: {
    adjusted_offer: false,
    market_researches: false
  },
  consent_channels: {
    direct: false,
    email: false,
    sms: false,
    call: false,
    digital: false,
    push: false
  },
  current_step: 1,
  loading: false,
  registration_problem: false,
  registration_problem_msg: ''
}

// getters
const getters = {}

// actions
const actions = {
  checkStep ({ dispatch }, mustBe) {
    if (mustBe > state.current_step) {
      dispatch('goToStep', state.current_step)
    }
  },
  goToStep ({ commit }, step) {
    commit('setCurrentStep', step)
    Router.push('/ustvari/vclanitev/korak' + step)
  },
  setChilds (context, childs) {
    context.commit('setChilds', childs)
  },
  joinClub ({ dispatch, commit }) {
    commit('setLoading', true)
    registrationService.joinClub({
      'personal': state.personal,
      'account': state.account,
      'interests': state.interests,
      'consents': state.consents,
      'consent_channels': state.consent_channels,
    }).then(
      data => {
        // got back to start
        commit('setCurrentStep', 1)
        if (data.has_email_confirmation) {
          dispatch('application/emailSentMessage', {
            title: 'Le še potrditev e-naslova',
            text: 'Za zaključek postopka prosim potrdite povezavo, ki smo jo poslali na vaš e-naslov.'
          }, { root: true })
        } else {
          //dispatch('application/fetchInitialInfo', {}, {root: true});
          window.location.replace('/api/login-done')
        }
      },
      error => {
        commit('setRegistrationProblem', error.message)
      }
    ).finally(() => {
      commit('setLoading', false)
    })

  },
  updateCityFromZIP ({ commit }) {
    registrationService.postOffice(state.personal.zip).then(
      data => {
        if (data.name) {
          commit('setCity', data.name)
          commit('setCountry', 'SI')
        }
      },
      () => {

      }
    )
  },
  refreshConsent ({ commit }) {
    let status = state.consents.market_researches == true && state.consents.adjusted_offer
    status = status && state.consent_channels.email == true && state.consent_channels.push == true
    status = status && state.consent_channels.sms == true && state.consent_channels.digital == true
    status = status && state.consent_channels.direct == true && state.consent_channels.call == true
    commit('setConfirmAllConsents', status)
  }
}

// mutations
const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },
  setChilds (state, childs) {
    state.personal.childs = childs;
  },
  setCity (state, city) {
    state.personal.city = city
  },
  setCurrentStep (state, step) {
    state.current_step = step
  },
  setCountry (state, city) {
    state.personal.country = city
  },
  setConfirmAllConsents (state, status) {
    state.confirm_all_consents = status
  },
  confirmAllConsents (state, { v }) {
    state.consents.adjusted_offer = v
    state.consents.market_researches = v
    state.consent_channels.direct = v
    state.consent_channels.email = v
    state.consent_channels.sms = v
    state.consent_channels.call = v
    state.consent_channels.digital = v
    state.consent_channels.push = v
    state.confirm_all_consents = v
  },
  setRegistrationProblem (state, message) {
    if (message != null) {
      state.registration_problem = true
    } else {
      state.registration_problem = false
    }
    state.registration_problem_msg = message
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
