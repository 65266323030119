import Router from '../../router'
import { userService } from '../../services'

// initial state
const state = {
  user_loaded: false,
  authenticated: false,
  authenticated_checked: false,
  authenticated_user: {
    email: '',
    connected_account_is_temp: false,
    can_upgrade_join: false
  },
  loading: false,
  confirm_code: undefined,
  invalid_username_password: false,
  invalid_username_password_message: null,
  invalid_username: false,
  invalid_username_message: null,
  invalid_password_message: null,
  create_account_problem: false,
  create_account_problem_msg: '',
  recover_method: null,
  recover_password_username: null
}

// getters
const getters = {}

// actions
const actions = {
  login ({ dispatch, commit }, { username, password, redirect }) {
    commit('setActionLoading', true)
    commit('setInvalidUsernamePassword', {
      status: false,
      message: null
    })
    userService.login(username, password).then(
      data => {
        commit('setAuthenticated', true)
        commit('setAuthenticatedUser', data['user'])
        if (data.redirect) {
          dispatch('application/redirect', {
            url: data.redirect,
            internal: data.redirect_internal || false,
            message: ''
          }, { root: true })
        } else {
          if (data.user.needs_password_change) {
            Router.push('/zahteva-novo-geslo')
          } else {
            if (redirect) {
              Router.push(redirect)
            } else {
              Router.push('/racun')
            }
          }
        }
      },
      error => {
        commit('setAuthenticated', false)
        if (error) {
          commit('setInvalidUsernamePassword', {
            status: true,
            message: error.message
          })
        }
      }
    ).then(function () {
      commit('setActionLoading', false)
    })
  },
  logout () {
    userService.logout().then(
      () => {
        window.location.replace('/')
      },
      () => {
        window.location.replace('/')
      }
    )
  },
  me ({ dispatch, commit }) {
    userService.me().then(
      data => {
        commit('setAuthenticated', data.authenticated)
        commit('setAuthenticatedUser', data['user'])
        if (data.authenticated) {
          if (data.redirect) {
            dispatch('application/redirect', {
              url: data.redirect,
              internal: data.redirect_internal || false,
              message: ''
            }, { root: true })
          } else {
            if (Router.currentRoute.name == 'login') {
              Router.push('/racun')
            }
          }
        }
      },
      () => {
        commit('setAuthenticated', false)
      }
    ).then(function () {
      commit('setAuthenticatedChecked', true)
    })
  },
  forgotPassword ({ dispatch, commit }, { username }) {
    commit('setActionLoading', true)
    commit('setInvalidUsername', false)
    userService.forgotPassword(username).then(
      data => {
        commit('setRecoverMethod', {
          type: data.type,
        })
        if (data.type == 'email') {
          dispatch('application/emailSentMessage', {
            title: 'Preverite poštni predal',
            text: 'Za spremembo gesla prosimo uporabite povezavo, ki smo jo poslali na vaš e-naslov.'
          }, { root: true })
        }
      },
      error => {
        commit('setInvalidUsername', {
          status: true,
          message: error.message
        })
      }
    ).then(function () {
      commit('setActionLoading', false)
    })
  },
  resetPassword ({ commit }, { password }) {
    commit('setActionLoading', true)
    commit('setInvalidPassword', {
      message: null
    })
    userService.resetPassword(password, this.state.auth.confirm_code, this.state.auth.recover_password_username).then(
      () => {
        Router.push('login')
      },
      error => {
        commit('setInvalidPassword', {
          message: error.message
        })
      }
    ).then(function () {
      commit('setActionLoading', false)
    })
  },
  createAccount ({ commit, rootState }, { username, password, name }) {
    let additional = {
      'consents': rootState.registration.consents,
      'consent_channels': rootState.registration.consent_channels
    }
    commit('setActionLoading', true)
    userService.createAccount(username, password, name, additional).then(
      () => {
        Router.push('/poslan-email')
      },
      error => {
        commit('setCreateAccountProblem', error.message)
      }
    ).then(function () {
      commit('setActionLoading', false)
    })
  }
}

// mutations
const mutations = {
  setActionLoading (state, status) {
    state.loading = status
  },
  setAuthenticatedChecked (state, status) {
    state.authenticated_checked = status
  },
  setAuthenticated (state, status) {
    state.user_loaded = true
    state.authenticated = status
    if (!status) {
      state.authenticated_user = {}
    }
  },
  setAuthenticatedUser (state, user) {
    if (!user) {
      user = {}
    }
    state.authenticated_user = user
  },
  setInvalidUsernamePassword (state, { status, message }) {
    state.invalid_username_password = status
    state.invalid_username_password_message = message
  },
  setInvalidUsername (state, { status, message }) {
    state.invalid_username = status
    state.invalid_username_message = message
  },
  setInvalidPassword (state, { message }) {
    state.invalid_password_message = message
  },
  setConfirmCode (state, confirmCode) {
    state.confirm_code = confirmCode
  },
  setCreateAccountProblem (state, message) {
    if (message != null) {
      state.create_account_problem = true
    } else {
      state.create_account_problem = false
    }
    state.create_account_problem_msg = message
  },
  setRecoverMethod (state, recover_method) {
    state.recover_method = recover_method
  },
  setRecoverPasswordUsername (state, username) {
    state.recover_password_username = username
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
