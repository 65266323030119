import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme: {
        themes: {
            light: {
                primary: '#22753a',
                primary_action: '#fada28',
                primary_action_small: '#fada28',
                accent: '22753a',
                dialog_head: '#22753a'
            },
            dark: {
                primary: '#22753a',
                primary_action: '#fada28',
                primary_action_small: '#fada28',
                accent: '22753a'
            }
        }
    }
});
