import Vue from 'vue'
import Router from 'vue-router'
import Store from './store/index'

const Login = () => import('./views/Login')
const ForgotPassword = () => import(/* webpackChunkName: "group-fpassword" */'./views/ForgotPassword')
const ForgotPasswordNew = () => import(/* webpackChunkName: "group-fpassword" */'./views/ForgotPasswordNew')

const Account = () => import(/* webpackChunkName: "group-account" */'./views/Account')
const PasswordChangeRequested = () => import(/* webpackChunkName: "group-account" */'./views/PasswordChangeRequested')
const TusTeam = () => import(/* webpackChunkName: "group-account" */'./views/tus-team/Team')

const EmailSent = () => import(/* webpackChunkName: "group-not-crucial" */'./views/EmailSent')
const NeedToBeMember = () => import(/* webpackChunkName: "group-not-crucial" */'./views/NeedToBeMember')

const LinkAccountCard = () => import(/* webpackChunkName: "group-link-account" */'./views/LinkAccountCard')
const ConfirmCard = () => import(/* webpackChunkName: "group-link-account" */'./views/ConfirmCard')
const ConfirmInProgress = () => import(/* webpackChunkName: "group-link-account" */'./views/ConfirmInProgress')

const ApplicationConsents = () => import(/* webpackChunkName: "group-consents" */'./views/ApplicationConsents')
const GlobalConsents = () => import(/* webpackChunkName: "group-consents" */'./views/GlobalConsents')

const CreateAccountSelect = () => import(/* webpackChunkName: "group-registration" */'./views/CreateAccountSelect')
const JoinClubPersonal = () => import(/* webpackChunkName: "group-registration" */'./views/JoinClubPersonal')
const JoinClubOtherData = () => import(/* webpackChunkName: "group-registration" */'./views/JoinClubOtherData')
const JoinClubAccount = () => import(/* webpackChunkName: "group-registration" */'./views/JoinClubAccount')
const CreateAccount = () => import(/* webpackChunkName: "group-registration" */'./views/CreateAccount')

const UnsubscribeEmail = () => import(/* webpackChunkName: "group-email" */'./views/UnsubscribeEmail')

// account delete related views
const DeleteAccount = () => import(/* webpackChunkName: "group-email" */'./views/DeleteAccount')

// family related views
const FamilyJoinExisting = () => import(/* webpackChunkName: "family" */'./views/family/JoinFamilyExistingUser')

// diners related views
const DinersAsk = () => import(/* webpackChunkName: "diners" */'./views/diners/DinersAsk')
const DinersAskVatNumber = () => import(/* webpackChunkName: "diners" */'./views/diners/DinerAskVatNumber')
const DinersConditions = () => import(/* webpackChunkName: "diners" */'./views/diners/DinersConditions')
const DinersConditionsCommunication = () => import(/* webpackChunkName: "diners" */'./views/diners/DinersConditionsCommunication')
const DinersDocuments = () => import(/* webpackChunkName: "diners" */'./views/diners/DinersDocuments')
const DinersEmployment = () => import(/* webpackChunkName: "diners" */'./views/diners/DinersEmployment')
const DinersSpendPay = () => import(/* webpackChunkName: "diners" */'./views/diners/DinersSpendPay')
const DinersSuccess = () => import(/* webpackChunkName: "diners" */'./views/diners/DinersSuccess')
const DinersVerifyGSM = () => import(/* webpackChunkName: "diners" */'./views/diners/DinersVerifyGSM')
const DinersVerifyGSMFromDiners = () => import(/* webpackChunkName: "diners" */'./views/diners/DinersVerifyGSMFromDiners')
const DinersUserData = () => import(/* webpackChunkName: "diners" */'./views/diners/DinerUserData')
const DinersExistingCardNumber = () => import(/* webpackChunkName: "diners" */'./views/diners/DinersExistingCardNumber')
const DinersSuccessExisting = () => import(/* webpackChunkName: "diners" */'./views/diners/DinersSuccessExisting')

// shopping list
const ShoppingListAddShared = () => import(/* webpackChunkName: "shopping-list" */'./views/shopping-list/ShoppingAddShared')


const EInvoiceConfirmCode = () => import(/* webpackChunkName: "einvoice" */'./views/einvoice/EInvoiceConfirm.vue')


Vue.use(Router)

function getRoutes () {
  let routes = [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/potrdi-email/:confirm_code*',
      name: 'confirm',
      component: ConfirmInProgress
    },
    {
      path: '/ustvari/izbira',
      name: 'create_account_select',
      component: CreateAccountSelect
    },
    {
      path: '/ustvari',
      name: 'create_account',
      component: CreateAccount
    },
    {
      path: '/ustvari/vclanitev/korak1',
      name: 'join_club_personal',
      component: JoinClubPersonal
    },
    {
      path: '/ustvari/vclanitev/korak2',
      name: 'join_club_other',
      component: JoinClubOtherData
    },
    {
      path: '/ustvari/vclanitev/korak3',
      name: 'join_club_account',
      component: JoinClubAccount
    },
    {
      path: '/pozabljeno-geslo',
      name: 'forgot_password',
      component: ForgotPassword
    },
    {
      path: '/novo-geslo',
      name: 'forgot_password_new',
      component: ForgotPasswordNew
    },
    {
      path: '/zahteva-novo-geslo',
      name: 'password_change_requested',
      component: PasswordChangeRequested
    },
    {
      path: '/racun',
      name: 'account',
      component: Account
    },
    {
      path: '/team',
      name: 'team',
      component: TusTeam
    },
    {
      path: '/poslan-email',
      name: 'email_sent',
      component: EmailSent
    },
    {
      path: '/racun/povezi',
      name: 'link_account_card',
      component: LinkAccountCard
    },
    {
      path: '/racun/povezi/potrdi-kartico',
      name: 'link_account_card_confirm_card',
      component: ConfirmCard
    },
    {
      path: '/storitev/soglasje',
      name: 'application_consents',
      component: ApplicationConsents
    },
    {
      path: '/soglasje',
      name: 'global_consents',
      component: GlobalConsents
    },
    {
      path: '/samo-clani',
      name: 'only_members',
      component: NeedToBeMember
    },
    {
      path: '/email/odjava/:user_key*',
      name: 'unsubscribe_email',
      component: UnsubscribeEmail
    }
  ]

  // append delete account routes
  // routes.push({
  //   path: '/racun/brisanje/via/email',
  //   name: 'account_delete_email',
  //   component: DeleteAccount
  // })
  routes.push({
    path: '/racun/brisanje',
    name: 'account_delete',
    component: DeleteAccount
  })
  // append family routes
  routes.push({
    path: '/druzina/pristopnica',
    name: 'family_join_existing',
    component: FamilyJoinExisting
  })

  // append diners routes
  routes.push({
    path: '/diners/vclanitev/izbira',
    name: 'diners_ask_join',
    component: DinersAsk
  })
  routes.push({
    path: '/diners/vclanitev/davcna',
    name: 'diners_ask_vat',
    component: DinersAskVatNumber
  })
  routes.push({
    path: '/diners/vclanitev/pogoji',
    name: 'diners_conditions',
    component: DinersConditions
  })
  routes.push({
    path: '/diners/vclanitev/pogoji/komunikacija',
    name: 'diners_conditions_communication',
    component: DinersConditionsCommunication
  })
  routes.push({
    path: '/diners/vclanitev/dokumenti',
    name: 'diners_documents',
    component: DinersDocuments
  })
  routes.push({
    path: '/diners/vclanitev/zaposlitev',
    name: 'diners_employment',
    component: DinersEmployment
  })
  routes.push({
    path: '/diners/vclanitev/poraba-placilo',
    name: 'diners_spend_pay',
    component: DinersSpendPay
  })
  routes.push({
    path: '/diners/vclanitev/uspeh',
    name: 'diners_success',
    component: DinersSuccess
  })
  routes.push({
    path: '/diners/vclanitev/o-uspeh',
    name: 'diners_success_exiting',
    component: DinersSuccessExisting
  })
  routes.push({
    path: '/diners/vclanitev/potrditev-gsm',
    name: 'diners_gsm',
    component: DinersVerifyGSM
  })
  routes.push({
    path: '/diners/vclanitev/potrditev-diners-gsm',
    name: 'diners_external_gsm',
    component: DinersVerifyGSMFromDiners
  })
  routes.push({
    path: '/diners/vclanitev/uporabniski-podatki',
    name: 'diners_user_data',
    component: DinersUserData
  })
  routes.push({
    path: '/diners/vclanitev/diners-obstojeca',
    name: 'diners_existing_card',
    component: DinersExistingCardNumber
  })

  //shopping list
  routes.push({
    path: '/nakupovalni-listek/dodaj/:link_id*',
    name: 'shopping_list_add',
    component: ShoppingListAddShared
  })

  //eracun
  routes.push({
    path: '/eracun/aktiviraj/:confirm_code*',
    name: 'einvoice_activate',
    component: EInvoiceConfirmCode
  })

  return routes
}

var router = new Router({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: getRoutes()
})

const ALLOW_FOR_ANONY_USERS = [
  'login', 'forgot_password', 'join_club_account', 'join_club_other', 'join_club_personal', 'create_account',
  'create_account_select', 'email_sent', 'forgot_password_new', 'application_consents', 'link_account_card', 'einvoice_activate'
]
const ALLOW_FOR_AUTH_USERS = [
  'account', 'link_account_card', 'join_club_personal', 'join_club_other', 'join_club_account', 'application_consents', 'family_join_existing',
  //'diners_user_data', 'diners_ask_join', 'diners_conditions', 'diners_conditions_communication', 'diners_documents', 'diners_employment', 'diners_spend_pay', 'diners_success', 'diners_gsm'
]

router.beforeEach((to, from, next) => {
  if (to.name == 'confirm') {
    return next()
  } /*else if(to.name == 'account'){
        window.location.replace('https://www.tus.si/uporabniski-profil');
    }*/ else {
    let is_authenticated = Store.state.auth.authenticated
    if (ALLOW_FOR_AUTH_USERS.indexOf(to.name) > -1 && ALLOW_FOR_ANONY_USERS.indexOf(to.name) == -1 && is_authenticated == false) {
      return next('login')
    } else if (ALLOW_FOR_ANONY_USERS.indexOf(to.name) > -1 && ALLOW_FOR_AUTH_USERS.indexOf(to.name) == -1 && is_authenticated) {
      //return next('racun');
    }
    if (to.name == null) {
      return next('/')
    }
    return next()
  }

})

export default router
