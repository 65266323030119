import client from "./client"

export const userService = {
    login,
    me,
    logout,
    forgotPassword,
    resetPassword,
    confirmEmail,
    createAccount,
    checkEmail,
    checkCard,
    cardInfo,
    confirmCard,
    sendSMSConfirmationCode,
    allAboutMe,
    changePassword,
    logoutOtherLocations,
    saveConsents,
    updateUserData,
    saveEnews,
    forgotPasswordCheckCode,
    disconnectCard
};

function me(){
    return client.get('/api/me/');
}

function allAboutMe(user_key){
    return client.post('/api/all-about-me/', {
        'user_key': user_key
    });
}

function login(username, password){
    return client.post('/api/login/', {
        'username': username,
        'password': password
    });
}

function logout() {
    return client.get('/api/logout/');
}

function forgotPassword(username){
    return client.post('/api/forgot-password/', {
        'username': username,
    });
}

function forgotPasswordCheckCode(username, confirm_code){
    return client.post('/api/forgot-password-check-code/', {
        'username': username,
        'confirm_code': confirm_code
    });
}

function resetPassword(password, confirm_code, username) {
    return client.post('/api/reset-password/', {
        'password': password,
        'confirm_code': confirm_code,
        'username': username
    });
}

function confirmEmail(confirm_code){
    return client.post('/api/confirm-email/', {
        'confirm_code': confirm_code,
    });
}

function createAccount(username, password, name, additional){
    return client.post('/api/create-account/', {
        'username': username,
        'password': password,
        'name': name,
        'additional': additional
    })
}

function checkEmail(email){
    return client.post('/api/check/email/', {
        'email': email
    })
}

function checkCard(card){
    return client.post('/api/check/card/', {
        'card': card
    })
}

function cardInfo(card){
    return client.post('/api/card-info/', {
        'card': card
    })
}

function confirmCard(card, method, value){
    return client.post('/api/confirm-card/', {
        'card': card,
        'method': method,
        'value': value
    })
}

function sendSMSConfirmationCode(card){
    return client.post('/api/send-confirmation-sms/', {
        'card': card
    })
}

function changePassword(current, newPassword){
    return client.post('/api/change-password/', {
        'current': current,
        'new_password': newPassword
    })
}

function logoutOtherLocations(){
    return client.get('/api/logout-other/');
}

function saveConsents(consents, by_need){
    if (by_need){
        consents['by_need'] = true;
    }
    return client.post('/api/save-consents/', consents);
}

function updateUserData(data){
    return client.post('/api/update-user/', data);
}


function saveEnews(action, cats, user_key){
    return client.post('/api/save-enews/', {
        'action': action,
        'categories': cats,
        'user_key': user_key
    });
}

function disconnectCard(){
    return client.post('/api/disconnect-card/', {});
}


