import client from "./client"

export const securityService = {
    deleteWebAccount,
    // deleteTusAccount
}

function deleteWebAccount(){
    return client.post('/api/security/delete-account', {});
}
//
// function deleteTusAccount(){
//     return client.post('/api/security/delete-tus-account', {});
// }
