import client from "./client"

export const tusTeamService = {
    getPayrolls,
    sendSMSConfirmationCode,
    sendEmailConfirmationCode,
    confirmEmailSMS
};

function getPayrolls(){
    return client.get('/api/team/payrolls');
}

function sendSMSConfirmationCode(phoneNumber){
    return client.post('/api/team/send-confirmation-sms', {
        'phone': phoneNumber
    });
}

function sendEmailConfirmationCode(email){
    return client.post('/api/team/send-confirmation-email', {
        'email': email
    });
}

function confirmEmailSMS(code){
    return client.post('/api/team/confirm-email-sms', {
        'code': code
    });
}
